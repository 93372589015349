import { InitialReducerState, SyncMailchimpPutQuery } from '@/types/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Entity {
    synced: boolean;
}

const initialState: InitialReducerState<Entity> = {
    entity: {
        synced: false,
    },
    error: undefined,
    loading: false,
};

export const defaultSyncMailchimpOptions: Partial<SyncMailchimpPutQuery> = {
    DeleteNonExisting: true,
    Test: true,
};

export default createSlice({
    name: 'mailchimp',
    initialState,
    reducers: {
        // setCompanyId: (
        //     state,
        //     action: PayloadAction<string /** @description Add two numbers */>
        // ) => {
        //     state.companyId = action.payload;
        // },
    },
});

// export const { setCompanyId, confirm } = deleteCompany.actions;
