/** @jsxImportSource theme-ui */
import { Flex, Heading, Box, Text, Grid } from 'theme-ui';
import Button from '@/components/Button';
import { useDialogState, Dialog, DialogBackdrop } from 'reakit/Dialog';

interface ConfirmatioModalProps {
    onYes?: () => void;
    onNo?: () => void;
    content: string;
    loading?: boolean;
    headingText?: string;
    yesText?: string;
    noText?: string;
}

export const ConfirmationModal: React.FC<ConfirmatioModalProps> = ({
    onNo = () => {},
    onYes = () => {},
    content,
    loading = false,
    headingText = 'Are you sure',
    yesText = 'Yes',
    noText = 'No',
    ...restProps
}) => {
    const dialog = useDialogState({ visible: true, modal: true });

    return (
        <DialogBackdrop {...dialog}>
            <Dialog
                hideOnClickOutside={false}
                sx={{
                    '&&': {
                        top: 'calc(50% - 100px)',
                        padding: 4,
                    },
                }}
                {...dialog}
            >
                {(props) => (
                    <Grid {...props}>
                        <Heading>{headingText}</Heading>
                        <Text>{content}</Text>
                        <Flex sx={{ gap: 2, justifyContent: 'center' }}>
                            <Button disabled={loading} onClick={onNo} variant="secondary">
                                No
                            </Button>
                            <Button
                                disabled={loading}
                                isLoading={loading}
                                onClick={onYes}
                                variant="danger"
                            >
                                Yes
                            </Button>
                        </Flex>
                    </Grid>
                )}
            </Dialog>
        </DialogBackdrop>
    );
};
