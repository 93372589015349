import { Spinner, Box, Checkbox, Label, Grid, Heading } from 'theme-ui';
import Button from '@/components/Button';
import React, { useState } from 'react';
import { useSyncMailchimpUsersMutation } from '@/store/apiService';
import { defaultSyncMailchimpOptions } from '@/store/mailchimp';
import { ConfirmationModal } from '../../components/ConfirmationModal';

export const SyncAllCompaniesMailchimpUsers: React.FC = ({ ...rest }) => {
    const [sync, syncState] = useSyncMailchimpUsersMutation();
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const [deleteNonExisting, setDeleteNonExisting] = useState(
        defaultSyncMailchimpOptions.DeleteNonExisting
    );

    const toggleDeleteNonExisting = () => setDeleteNonExisting(!deleteNonExisting);

    return (
        <>
            {confirmationModalVisible && !syncState.isSuccess && !syncState.isError ? (
                <ConfirmationModal
                    loading={syncState.isLoading}
                    content="This will sync all company adminstrators to mailchimp, are you sure?"
                    onNo={() => {
                        setConfirmationModalVisible(false);
                    }}
                    onYes={() => {
                        sync({
                            DeleteNonExisting: deleteNonExisting,
                        });
                        setConfirmationModalVisible(false);
                    }}
                />
            ) : null}
            <Grid gap={2}>
                <Heading>Sync all company adminstrators to mailchimp</Heading>
                <Box>
                    <Label>
                        <Checkbox checked={deleteNonExisting} onChange={toggleDeleteNonExisting} />
                        Remove all deleted customers
                    </Label>
                </Box>
                <Button
                    isLoading={syncState.isLoading}
                    variant="icon"
                    onClick={(evt) => {
                        evt.preventDefault();
                        syncState.reset();
                        setConfirmationModalVisible(true);
                    }}
                >
                    Sync
                </Button>
            </Grid>
        </>
    );
};
